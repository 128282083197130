import React, { useEffect, useState } from "react"
import Seo from "../../components/SEO"
import { graphql, Link, useStaticQuery } from "gatsby"
import LaunchIcon from "../../assets/img/launch-evaluate.inline.svg"
import ConsultIcon from "../../assets/img/process-consultation.inline.svg"
import DesignIcon from "../../assets/img/process-design-develop.inline.svg"
import { StaticImage } from "gatsby-plugin-image"
import { useContextValues } from "../../context/NavBarContext"
import ProjectPortfolio from "../../components/DashboardPortfolio/PortfolioList"
import StarImage from "../../assets/img/star.png"
import ClutchLogo from "../../assets/img/clutchWhite.webp"

import TestimonialSlider from "../../components/DashboardTestimonials/TestimonialSlider"
import Faq from "../../components/PPC/ReactDevPpc/Faq"
import ArrowWhite from "../../assets/img/arrow-right.webp"
import LandingFooter from "../../components/PPC/Landing/LandingFooter"
import HeaderLanding from "../../components/PPC/Landing/HeaderLanding"
import ContactFormLanding from "../../components/PPC/Landing/ContactFormLanding"


const solutions = [
    {
        "title": "Tailored to Your Organization",
        "description": [
            [
                "",
                "No two learning environments are the same. Our LMS adapts to your goals, ensuring a unique, branded experience. "
            ]
        ],
    },
    {
        "title": "Scalable & Future-Proof",
        "description": [
            [
                "",
                "From startups to enterprises, our LMS grows with you. Add features, users, and integrations as your needs evolve."
            ]
        ],
    },
    {
        "title": "Engaging Learning Experiences",
        "description": [
            [
                "",
                "Interactive modules, gamification, and mobile compatibility keep learners motivated and engaged. "
            ]
        ],
    },
    {
        "title": "Data-Driven Insights",
        "description": [
            [
                "",
                "Track progress, monitor engagement, and optimize learning paths with powerful analytics."
            ]
        ],
    },
]

const faqs = [
    {
        "key": "01",
        "question": "What is a Learning Management System (LMS)?",
        "answer": [
            "An LMS is a digital platform that helps organizations create, manage, and deliver educational or training content. It enables streamlined course creation, user management, performance tracking, and reporting for educational institutions, businesses, and non-profits."
        ]
    },
    {
        "key": "02",
        "question": "How is a custom LMS different from ready-made platforms like Moodle or Teachable?",
        "answer": [
            "Custom LMS platforms are tailored to your unique requirements, offering complete control over features, design, scalability, and integrations. In contrast, ready-made platforms come with pre-set limitations that might not align with your organization's specific needs."
        ]
    },
    {
        "key": "03",
        "question": "How long does it take to develop a custom LMS?",
        "answer": [
            "You can get customization on our existing LMS system in as little as 2 weeks. However, if you require advanced features or extensive add-ons, the timeline can extend up to 16 weeks. We ensure a streamlined process to meet your needs efficiently."
        ]
    },
    {
        "key": "04",
        "question": "Can a custom LMS integrate with other tools or systems we already use?",
        "answer": [
            "Absolutely! Custom LMS solutions are built to integrate seamlessly with CRMs, HR management tools, payment gateways, video conferencing apps, and more to ensure a cohesive ecosystem."
        ]
    },
    {
        "key": "05",
        "question": "What ongoing support and maintenance are offered after development?",
        "answer": [
            "We provide continuous support, including performance monitoring, updates, feature enhancements, and technical troubleshooting, ensuring your LMS remains secure, efficient, and up-to-date."
        ]
    }
]

const navList = [
    { text: "About Us", link: "/company/" },
    { text: "Our Work", link: "#our-work" },
    { text: "AI Features", link: "#ai-features" },
    { text: "Testimonial", link: "#testimonial" },
    { text: "FAQs", link: "#faq" },
]
const LMSDevelopment = () => {
    const [expandedItem, setExpandedItem] = useState(0)

    const {
        allLandingPortfoliosJson: { projects },
    } = useStaticQuery(graphql`
        query {
            allLandingPortfoliosJson(
                filter: {idx: {in: ["tutorac", "getlitt", "PartsPractise", "College Ease", "Guided LXP"]}}
            ) {
                projects: nodes {
                idx
                slug
                featuredImage {
                    childImageSharp {
                    gatsbyImageData
                    }
                }
                backgroundStyles
                alt
                title
                subTitle
                industry
                budget
                country
                description
                clutchLink
                }
            }
        }
    `)

    const { changeCurrentPath, changeNavColor } = useContextValues()

    useEffect(() => {
        changeCurrentPath(location.pathname)
        changeNavColor("white")
        return (() => changeNavColor("black"))
    }, [])

    const goToSection = (id) => {
        document.getElementById(id)?.scrollIntoView({ behavior: "smooth", block: "start" })
    }

    return (
        <>
            <HeaderLanding navList={navList} />

            <section id="contact" className="banner-gradient pt-[3.75rem] md:pt-[150px] md:pb-[70px] pb-[70px] lg:pb-[70px] bg-[#343434] relative before:content-[''] before:w-[56.5625rem] before:h-[56.5625rem] before:absolute before:bg-no-repeat before:top-[-5rem] before:right-0 z-[1] before:-z-50 before:opacity-[0.3] before:blur-[5.625rem] overflow-hidden">
                <div className="container">
                    <div className="flex flex-wrap justify-between items-start">
                        <div className="xl:max-w-[34.9375rem] lg:max-w-[28rem] max-w-full w-full">
                            <div className="w-full">
                                <h1 className="max-[1400px]:text-[3.125rem] max-sm:text-[2.375rem] max-sm:leading-[48px] text-[3.25rem] max-[1400px]:leading-[4rem] leading-[3.75rem] pb-[1.38rem] font-blancotrialbold text-white text-left">
                                    Empower Learning with a   <span className="text-[#3FB955] italic">Custom LMS </span> Built for Your Needs
                                </h1>
                                <h3 className="text-white md:text-lg text-base font-gordita font-normal md:leading-[1.875rem] max-w-[31rem]">
                                    Streamline training, enhance engagement, and scale seamlessly. Explore our powerful, flexible LMS solutions for businesses, schools, and training centers.
                                </h3>

                                <div className="text-xs leading-[0.938rem] mt-5 max-md:mb-[3.125rem] md:mt-[1.625rem] flex items-center gap-2 flex-wrap align-middle ">
                                    <span className="text-sm md:text-base text-white">4.9/5</span>
                                    <span className="flex flex-nowrap items-center gap-[0.188rem]">
                                        <img
                                            src={StarImage}
                                            alt="Star icon"
                                            className="max-w-[0.75rem]"
                                        />
                                        <img
                                            src={StarImage}
                                            alt="Star icon"
                                            className="max-w-[0.75rem]"
                                        />
                                        <img
                                            src={StarImage}
                                            alt="Star icon"
                                            className="max-w-[0.75rem]"
                                        />
                                        <img
                                            src={StarImage}
                                            alt="Star icon"
                                            className="max-w-[0.75rem]"
                                        />
                                        <img
                                            src={StarImage}
                                            alt="Star icon"
                                            className="max-w-[0.75rem]"
                                        />
                                    </span>
                                    <span className="text-white">based on <em className="font-gorditamedium not-italic">41</em> verified reviews</span>
                                    <img
                                        src={ClutchLogo}
                                        alt="Clutch"
                                        className="max-w-[3.75rem] object-cover"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="contact-form-part relative max-lg:mx-auto max-lg:mt-10 w-full md:w-[26.875rem] lg:w-[32.8125rem] before:content-[''] before:w-[26.375rem] before:h-[26.375rem] before:bg-[url('../assets/img/dots-bg.svg')] before:bg-center before:bg-no-repeat before:bg-cover before:absolute before:top-[50%] before:translate-y-[-50%] before:left-[-90px] before:z-[-1] max-sm:mt-0">
                            <div className="form-wrapper  rounded-[2.125rem] py-10 px-[2.188rem] bg-white-200">
                                <ContactFormLanding
                                    submitBtnTxt="Submit"
                                    currency={'USD'}
                                    spacingCss="md:pb-[1.5rem]"
                                    submitBtnCss="text-white bg-primary min-[1441px]:text-[1.25rem] max-[1440px]:text-[1.15rem] text-base text-center rounded-[1.875rem] px-3 py-4 max-[1440px]:py-[0.75rem] w-full font-gorditamedium hover:bg-orange-500 hover:text-white transition-all"
                                    formCss="rounded-xl"
                                    NameCss="text-sm w-full text-black py-3 px-5 max-[1440px]:py-[0.75rem] bg-transparent rounded-[0.625rem] border border-[#C8CBD2] focus-visible:border-[#C8CBD2] focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none"
                                    emailCss="text-sm w-full text-black py-3 px-5 max-[1440px]:py-[0.75rem] bg-transparent rounded-[0.625rem] border border-[#C8CBD2] focus-visible:border-[#C8CBD2] focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none"
                                    phoneNumberCss="text-sm w-full text-black py-3 px-5 max-[1440px]:py-[0.75rem] bg-transparent rounded-[0.625rem] border border-[#C8CBD2] focus-visible:border-[#C8CBD2] focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none"
                                    budgetCss="select-budget bg-[url('../assets/img/Path1.svg')] bg-no-repeat bg-[center_right_15px] appearance-none !text-sm leading-[1.375rem] w-full pt-[0.875rem] pb-3 !px-5 bg-transparent rounded-[0.625rem] border border-white-800 focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none foucs-visible:border-[#C8CBD2]"
                                    textAreaCss="align-top h-40 max-[1440px]:h-24 resize-none !text-sm w-full text-black py-3 !px-5 bg-transparent rounded-[0.625rem] border border-[#C8CBD2] focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none foucs-visible:border-[#C8CBD2]"
                                    formTitle="Share your website requirement."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-10 md:py-12 xl:py-[110px] xl:pb-[96px]">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-[20px] font-blancomedium text-center">
                            Why Choose Our <span className="text-[#3eb955] italic"> LMS?</span>
                        </h2>
                    </div>

                    <div className="grid lg:grid-cols-2 md:gap-y-6 lg:gap-x-10 xl:gap-x-20 xl:grid-cols-[1fr_592px] mt-[30px] items-start">
                        <div className="">
                            {solutions.map(({ title, description }, idx) => {
                                return (
                                    <div
                                        key={`solution-${idx}`}
                                        className={`${expandedItem === idx ? '!bg-[#E1FBDA99] border border-[#9BD28F]' : ''} bg-[#FFFFFF] px-5 py-6 lg:px-[1.875rem] lg:py-[1.563rem] border-[0.063rem] border-solid border-[#9BD28F7D] mb-[0.625rem] rounded-[1.25rem] cursor-pointer hover:bg-[#E1FBDA99] transition-all duration-500`}
                                        onClick={() => setExpandedItem(idx)}
                                    >
                                        <h3 className="text-black text-xl leading-[1.875rem] font-gordita font-medium">{title}</h3>
                                        {expandedItem === idx && <div className="mt-[0.625rem]">
                                            <p className="text-base">{description}</p>
                                            <StaticImage src={"../../assets/img/Online-trainings-main-shot01.webp"} alt={"Learner"} className="block lg:hidden mt-4 max-lg:max-w-lg w-full mx-auto" />
                                        </div>}
                                    </div>
                                )
                            })}
                        </div>
                        <figure className="max-lg:max-w-lg max-lg:mx-auto rounded-[0.875rem] overflow-hidden max-lg:hidden">
                            <StaticImage src={"../../assets/img/Online-trainings-main-shot01.webp"} alt={"Learner"} />
                        </figure>
                    </div>
                </div>
            </section>

            <section className="lg:pb-[6.875rem] overflow-hidden md:pb-[3.125rem] pb-[1.875rem]">
                <div className="container">
                    <div className="grid-div bg-[#333333] max-sm:px-[20px] py-[30px] pl-[3.75rem] pr-[1.875rem] rounded-[30px] grid grid-cols-[1fr] md:grid-cols-[1fr_1fr] lg:grid-cols-[auto_270px] gap-[1.5625rem] md:gap-[3.125rem] lg:gap-[5rem] green-bg-gradient relative before:content-[''] before:w-[37.0625rem] before:h-[37.0625rem] before:absolute before:bg-no-repeat before:top-[-5rem] before:right-[-6.875rem] z-[1] before:-z[-1] before:opacity-[0.3] before:blur-[5.625rem] overflow-hidden">
                        <div className="grid-content">
                            <p className="font-gorditamedium text-sm leading-[1.5] text-white mb-[0.75rem]">CASE STUDY</p>
                            <p className="text-[1.375rem] text-white leading-[1.45] font-gorditaregular font-normal">
                                Tutorac: Empowering learners with <span className="italic font-gorditamedium">on-demand courses, live training</span> is AWS Chime, and personalized paths.
                            </p>
                            <div className="link-green inline-flex flex-wrap items-center relative gap-[0.625rem] mt-4 group transition duration-500 ease-linear">
                                <Link to={"/portfolio/online-learning-platform/"} className="ltext-lg relative leading-[1.5] italic font-gorditamedium text-[#4BD965] before:content-[''] before:w-full before:h-[1px] before:bg-[#4BD965] before:absolute before:left-0 before:bottom-0 ">
                                    Read entire case study
                                </Link>
                                <img
                                    src={ArrowWhite}
                                    alt=""
                                    className="w-[0.4375rem] group-hover:translate-x-1 transition duration-500 ease-linear"
                                />
                            </div>
                        </div>
                        <div className="grid-img relative z-10">
                            <StaticImage src="../../assets/img/case-study2.webp" alt="RemoteCo" className="rounded-lg block mx-auto md:inline-block" />
                        </div>
                    </div>

                    <div className="btn-row mt-6 md:mt-[1.875rem] lg:mt-[3.75rem] text-center">
                        <div onClick={() => goToSection("contact")} className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-transparent hover:text-black uppercase relative pr-[2.3125rem]">Get a Free Demo

                            <span className="inline-flex justify-center items-center rounded-full ml-[0.625rem]  h-[2.125rem] w-[2.125rem] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent absolute right-[5px] top-[5px]">
                                <img
                                    src={ArrowWhite}
                                    alt="ArrowRight"
                                    className="absolute left-1/2 top-4 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                />
                                <img
                                    src={ArrowWhite}
                                    alt="ArrowRight"
                                    className="absolute -left-16 top-4 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2 w-[7px] h-[14px] ease-in duration-300 group-hover:invert-[1] group-hover:brightness-[1]"
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-[#F8F7F3] lg:py-[6.875rem] md:py-[3.125rem] py-[1.875rem]">
                <div className="container max-w-[67.8125rem] max-[991px]:max-w-[100%]">
                    <div className="benefit bg-[#fff] rounded-[1.25rem] border border-[#D3D8E0]">
                        <h2 className="text-black text-[2rem] leading-[2.1875rem] text-center lg:leading-[3.375rem] font-blancomedium py-[1.875rem] md:px-[1.875rem] lg:px-[3.3125rem] border-b border-[#D3D8E0] max-sm:px-5">
                            <span className="text-primary italic">Solving Bottlenecks</span> with Existing Providers
                            <p className="text-black md:text-lg text-base font-gordita font-normal md:leading-[1.875rem] mt-5">
                                Breaking the Limits of Traditional Platforms <br className=" hidden lg:block"></br>
                                Users of platforms like Teachable, Udemy, Thinkific, and Kajabi often hit roadblocks such as:
                            </p>
                        </h2>
                        <div className="grid pt-[1.875rem] pb-[3.125rem] lg:px-[3.125rem] px-[25px] lg:gap-[5.625rem] gap-[3.125rem] grid-cols-2 max-sm:grid-cols-[1fr]">
                            <div className="grid-left">
                                <div className="text-center">
                                    <span className="font-gorditaregular inline-block bg-cream italic md:pt-[0.563rem] md:pb-[0.375rem] pt-[0.375rem] pb-[0.375rem] px-[1.375rem] rounded-[6.25rem] text-sm border border-black mb-[1.875rem]">Problems</span>
                                </div>
                                <ul className="text-left service-tabs">
                                    <li className="text-black text-base leading-relaxed relative before:content-[''] before:w-[1.125rem] before:h-[1.125rem] before:absolute before:left-0 before:top-[0.125rem] before:bg-[url('../assets/img/tick-circle.webp')] before:bg-center before:bg-no-repeat block pl-[2.375rem] pb-5 max-sm:pb-[0.625rem] min-h-[4.25rem]">
                                        <div className="flex items-center justify-between md:text-lg text-base select-none font-gorditamedium text-black relative pr-[3.5rem] cursor-pointer">Limited Customization</div>
                                        Generic templates restrict unique branding and interactivity.
                                    </li>
                                    <li className="text-black text-base leading-relaxed relative before:content-[''] before:w-[1.125rem] before:h-[1.125rem] before:absolute before:left-0 before:top-[0.125rem] before:bg-[url('../assets/img/tick-circle.webp')] before:bg-center before:bg-no-repeat block pl-[2.375rem] pb-5 max-sm:pb-[0.625rem] min-h-[4.25rem]">
                                        <div className="flex items-center justify-between md:text-lg text-base select-none font-gorditamedium text-black relative pr-[3.5rem] cursor-pointer">Scalability Issues</div>
                                        High growth leads to performance lags and feature limitations.
                                    </li>
                                    <li className="text-black text-base leading-relaxed relative before:content-[''] before:w-[1.125rem] before:h-[1.125rem] before:absolute before:left-0 before:top-[0.125rem] before:bg-[url('../assets/img/tick-circle.webp')] before:bg-center before:bg-no-repeat block pl-[2.375rem] pb-5 max-sm:pb-[0.625rem] min-h-[4.25rem]">
                                        <div className="flex items-center justify-between md:text-lg text-base select-none font-gorditamedium text-black relative pr-[3.5rem] cursor-pointer">Inflexible Features</div>
                                        Difficulty integrating external tools and expanding functionalities.
                                    </li>
                                    <li className="text-black text-base leading-relaxed relative before:content-[''] before:w-[1.125rem] before:h-[1.125rem] before:absolute before:left-0 before:top-[0.125rem] before:bg-[url('../assets/img/tick-circle.webp')] before:bg-center before:bg-no-repeat block pl-[2.375rem] pb-5 max-sm:pb-[0.625rem] min-h-[4.25rem]">
                                        <div className="flex items-center justify-between md:text-lg text-base select-none font-gorditamedium text-black relative pr-[3.5rem] cursor-pointer">Data Ownership Concerns</div>
                                        Limited access to in-depth analytics and learner data.
                                    </li>
                                </ul>
                            </div>
                            <div className="grid-right">
                                <div className="text-center">
                                    <span className="font-gorditaregular inline-block bg-cream italic md:pt-[0.563rem] md:pb-[0.375rem] pt-[0.375rem] pb-[0.375rem] px-[1.375rem] rounded-[6.25rem] text-sm border border-black mb-[1.875rem]">Our solutions</span>
                                </div>
                                <div>
                                    <ul>
                                        <li className={`md:max-w-[24rem] relative md:pr-[1.875rem]`}>
                                            <div className="flex items-center justify-between md:text-lg text-base select-none font-gorditamedium text-black relative pr-[3.5rem] cursor-pointer">Complete Customization</div>
                                            Create bespoke designs and interactive elements without limits.
                                        </li>
                                        <li className={`md:max-w-[24rem] relative md:pr-[1.875rem] pt-5`}>
                                            <div className="flex items-center justify-between md:text-lg text-base select-none font-gorditamedium text-black relative pr-[3.5rem] cursor-pointer">Scalable Infrastructure</div>
                                            Handle growth effortlessly with robust, scalable systems.
                                        </li>
                                        <li className={`md:max-w-[24rem] relative md:pr-[1.875rem] pt-5`}>
                                            <div className="flex items-center justify-between md:text-lg text-base select-none font-gorditamedium text-black relative pr-[3.5rem] cursor-pointer">Seamless Integrations</div>
                                            Incorporate any third-party tools or APIs your business requires.
                                        </li>
                                        <li className={`md:max-w-[24rem] relative md:pr-[1.875rem] pt-5`}>
                                            <div className="flex items-center justify-between md:text-lg text-base select-none font-gorditamedium text-black relative pr-[3.5rem] cursor-pointer">Enhanced Data Control</div>
                                            Own your learner data and gain comprehensive insights.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="lg:pt-14 md:pt-10 pt-8 lg:pb-14 md:pb-10 pb-8">
                <div className="container">
                    <div className="text-center md:w-10/12 w-full mx-auto mb-7">
                        <h3 className="max-w-full md:text-[2rem] text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] font-blancotrialbold">
                            Key <span className="text-primary italic">features</span>
                        </h3>
                    </div>
                    <div className="lg:p-[3.125rem] p-[1.5rem] bg-[#F7F8F9] rounded-[1.25rem]">
                        <div className="flex md:flex-row flex-col md:p-[1.688rem] p-5 bg-white-200 mb-7 rounded-lg items-center">
                            <h4 className="md:w-[17.1875rem] w-full text-black md:text-xl text-lg font-gorditamedium md:mb-0 mb-2">
                                Customizable Interface
                            </h4>
                            <div className="md:w-[calc(100%-17.1875rem)] w-full md:text-xl text-xl font-gorditamedium">
                                <h4
                                    id="description_1"
                                    className="leading-[1.69] text-grey font-gorditamedium text-left md:text-xl text-[1rem] md:border-l border-[#D3D8E0] md:pl-7 pl-0"
                                >
                                    Personalize the platform to reflect your brand.
                                </h4>
                            </div>
                        </div>
                        <div className="flex md:flex-row flex-col md:p-[1.688rem] p-5 bg-white-200 mb-7 rounded-lg items-center">
                            <h4 className="md:w-[17.1875rem] w-full text-black md:text-xl text-lg font-gorditamedium md:mb-0 mb-2">
                                Mobile-Ready
                            </h4>
                            <div className="md:w-[calc(100%-17.1875rem)] w-full md:text-xl text-xl font-gorditamedium">
                                <h4
                                    id="description_1"
                                    className="leading-[1.69] text-grey font-gorditamedium text-left md:text-xl text-[1rem] md:border-l border-[#D3D8E0] md:pl-7 pl-0"
                                >
                                    Seamless access across devices for on-the-go learning.
                                </h4>
                            </div>
                        </div>
                        <div className="flex md:flex-row flex-col md:p-[1.688rem] p-5 bg-white-200 mb-7 rounded-lg items-center">
                            <h4 className="md:w-[17.1875rem] w-full text-black md:text-xl text-lg font-gorditamedium md:mb-0 mb-2">
                                Gamification
                            </h4>
                            <div className="md:w-[calc(100%-17.1875rem)] w-full md:text-xl text-xl font-gorditamedium">
                                <h4
                                    id="description_1"
                                    className="leading-[1.69] text-grey font-gorditamedium text-left md:text-xl text-[1rem] md:border-l border-[#D3D8E0] md:pl-7 pl-0"
                                >
                                    Boost engagement with points, badges, and leaderboards.
                                </h4>
                            </div>
                        </div>
                        <div className="flex md:flex-row flex-col md:p-[1.688rem] p-5 bg-white-200 mb-7 rounded-lg items-center">
                            <h4 className="md:w-[17.1875rem] w-full text-black md:text-xl text-lg font-gorditamedium md:mb-0 mb-2">
                                Advanced Reporting
                            </h4>
                            <div className="md:w-[calc(100%-17.1875rem)] w-full md:text-xl text-xl font-gorditamedium">
                                <h4
                                    id="description_1"
                                    className="leading-[1.69] text-grey font-gorditamedium text-left md:text-xl text-[1rem] md:border-l border-[#D3D8E0] md:pl-7 pl-0"
                                >
                                    Gain insights with performance tracking and feedback loops.
                                </h4>
                            </div>
                        </div>
                        <div className="flex md:flex-row flex-col md:p-[1.688rem] p-5 bg-white-200 mb-7 rounded-lg items-center">
                            <h4 className="md:w-[17.1875rem] w-full text-black md:text-xl text-lg font-gorditamedium md:mb-0 mb-2">
                                Integration Friendly
                            </h4>
                            <div className="md:w-[calc(100%-17.1875rem)] w-full md:text-xl text-xl font-gorditamedium">
                                <h4
                                    id="description_1"
                                    className="leading-[1.69] text-grey font-gorditamedium text-left md:text-xl text-[1rem] md:border-l border-[#D3D8E0] md:pl-7 pl-0"
                                >
                                    Connect with CRM, HR systems, and video platforms easily.
                                </h4>
                            </div>
                        </div>
                        <div className="flex md:flex-row flex-col md:p-[1.688rem] p-5 bg-white-200 mb-0 rounded-lg items-center">
                            <h4 className="md:w-[17.1875rem] w-full text-black md:text-xl text-lg font-gorditamedium md:mb-0 mb-2">
                                Multi-Language Support
                            </h4>
                            <div className="md:w-[calc(100%-17.1875rem)] w-full md:text-xl text-xl font-gorditamedium">
                                <h4
                                    id="description_1"
                                    className="leading-[1.69] text-grey font-gorditamedium text-left md:text-xl text-[1rem] md:border-l border-[#D3D8E0] md:pl-7 pl-0"
                                >
                                    Cater to diverse teams and learners worldwide.
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className="btn-row mt-6 md:mt-[1.875rem] lg:mt-[3.75rem] text-center">
                        <div onClick={() => goToSection("contact")} className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-transparent hover:text-black uppercase relative pr-[2.3125rem]">Get a Free Demo

                            <span className="inline-flex justify-center items-center rounded-full ml-[0.625rem]  h-[2.125rem] w-[2.125rem] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent absolute right-[5px] top-[5px]">
                                <img
                                    src={ArrowWhite}
                                    alt="ArrowRight"
                                    className="absolute left-1/2 top-4 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                />
                                <img
                                    src={ArrowWhite}
                                    alt="ArrowRight"
                                    className="absolute -left-16 top-4 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2 w-[7px] h-[14px] ease-in duration-300 group-hover:invert-[1] group-hover:brightness-[1]"
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </section>

            <section id="ai-features" className="lg:pt-[6.8125rem] md:pt-20 pt-14 lg:pb-[6.875rem] md:pb-10 pb-8 bg-[#F5F5F5] lg:mt-[3.4375rem] lg:mb-[3.4375rem]">
                <div className="container">
                    <div className="title-sec m-auto w-full max-w-full md:pb-10 pb-8">
                        <h2 className="md:text-[2.625rem] text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] pb-1 font-blancotrialbold text-center">
                            <span className="text-primary italic">AI-Driven</span> Features for Modern LMS
                        </h2>
                    </div>
                    <ul className="grid md:grid-cols-2 lg:grid-cols-3 gap-[30px]">
                        <li className="py-[3.125rem] px-[1.875rem] bg-[#fff] rounded-[1.25rem] text-center">
                            <p className="text-xl font font-gorditamedium mb-[10px]">Adaptive Learning Management</p>
                            <p className="font-gorditaregular text-base">AI optimizes course delivery based on user progress and learning patterns.</p>
                        </li>
                        <li className="py-[3.125rem] px-[1.875rem] bg-[#fff] rounded-[1.25rem] text-center">
                            <p className="text-xl font font-gorditamedium mb-[10px]">Smart Content Automation</p>
                            <p className="font-gorditaregular text-base">Automate content recommendations, updates, and organization with AI-driven insights.</p>
                        </li>
                        <li className="py-[3.125rem] px-[1.875rem] bg-[#fff] rounded-[1.25rem] text-center">
                            <p className="text-xl font font-gorditamedium mb-[10px]">AI-Powered Assessments</p>
                            <p className="font-gorditaregular text-base">Enhance evaluations with automated grading, feedback, and personalized testing.</p>
                        </li>
                        <li className="py-[3.125rem] px-[1.875rem] bg-[#fff] rounded-[1.25rem] text-center">
                            <p className="text-xl font font-gorditamedium mb-[10px]">Advanced LMS Analytics</p>
                            <p className="font-gorditaregular text-base">Leverage AI to track engagement, measure outcomes, and improve learning strategies.</p>
                        </li>
                        <li className="py-[3.125rem] px-[1.875rem] bg-[#fff] rounded-[1.25rem] text-center">
                            <p className="text-xl font font-gorditamedium mb-[10px]">AI Chatbots for LMS Support</p>
                            <p className="font-gorditaregular text-base">Provide 24/7 assistance for learners, instructors, and administrators.</p>
                        </li>
                    </ul>
                </div>
            </section>

            <section className="pt-14 md:pt-20 ">
                <div className="container">
                    <div className="title-sec m-auto w-full max-w-full md:pb-10 pb-8">
                        <h2 className="md:text-[2.625rem] text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] pb-1 font-blancotrialbold text-center">
                            <span className="text-primary italic">Who</span> We Serve
                        </h2>
                    </div>
                    <ul className="grid md:grid-cols-2 lg:grid-cols-3 gap-[1.875rem]">
                        <li className="bg-white-300 md:mb-[1.875rem]  w-full max-w-full py-10 px-7 flex flex-col text-center rounded-[1.25rem] justify-center items-center">
                            <StaticImage src="../../assets/img/training-industry.inline.svg" alt="Corporate Training" />
                            <h3 className="md:text-2xl text-xl pb-1 font-blancotrialbold leading-9 text-center pt-4">
                                Corporations
                            </h3>
                            <p className="text-grey text-base leading-6 mt-[10px]">
                                Upskill employees and ensure compliance with ease.
                            </p>
                        </li>
                        <li className="bg-white-300 md:mb-[1.875rem]  w-full max-w-full  py-10 px-7 flex flex-col text-center rounded-[1.25rem] justify-center items-center">
                            <StaticImage src="../../assets/img/higher-education.inline.svg" alt="Higher Education" />
                            <h3 className="md:text-2xl text-xl pb-1 font-blancotrialbold leading-9 text-center pt-4">
                                Educational Institutions
                            </h3>
                            <p className="text-grey text-base leading-6 mt-[10px]">
                                Manage online courses, track student progress, and encourage collaboration.
                            </p>
                        </li>
                        <li className="bg-white-300 md:mb-[1.875rem]  w-full max-w-full  py-10 px-7 flex flex-col text-center rounded-[1.25rem] justify-center items-center">
                            <StaticImage src="../../assets/img/healthcare.inline.svg" alt="Healthcare" />
                            <h3 className="md:text-2xl text-xl pb-1 font-blancotrialbold leading-9 text-center pt-4">
                                Training Providers
                            </h3>
                            <p className="text-grey text-base leading-6 mt-[10px]">
                                Deliver dynamic content for various industries.
                            </p>
                        </li>
                        <li className="bg-white-300 md:mb-[1.875rem]  w-full max-w-full  py-10 px-7 flex flex-col text-center rounded-[1.25rem] justify-center items-center">
                            <StaticImage src="../../assets/img/non-profit.inline.svg" alt="Non-Profit" />
                            <h3 className="md:text-2xl text-xl pb-1 font-blancotrialbold leading-9 text-center pt-4">
                                Non-Profits
                            </h3>
                            <p className="text-grey text-base leading-6 mt-[10px]">
                                Expand reach and improve accessibility to learning.
                            </p>
                        </li>
                        <li className="bg-white-300 md:mb-[1.875rem]  w-full max-w-full  py-10 px-7 flex flex-col text-center rounded-[1.25rem] justify-center items-center">
                            <StaticImage src="../../assets/img/retail-ecommerce.inline.svg" alt="Retail & E-commerce" />
                            <h3 className="md:text-2xl text-xl pb-1 font-blancotrialbold leading-9 text-center pt-4">
                                Healthcare
                            </h3>
                            <p className="text-grey text-base leading-6 mt-[10px]">
                                Manage certifications and continuous education.
                            </p>
                        </li>
                        <li className="bg-white-300 md:mb-[1.875rem]  w-full max-w-full  py-10 px-7 flex flex-col text-center rounded-[1.25rem] justify-center items-center">
                            <StaticImage src="../../assets/img/finance-banking.inline.svg" alt="Finance & Banking" />
                            <h3 className="md:text-2xl text-xl pb-1 font-blancotrialbold leading-9 text-center pt-4">
                                Retail
                            </h3>
                            <p className="text-grey text-base leading-6 mt-[10px]">
                                Onboard staff and improve customer service knowledge.
                            </p>
                        </li>
                        <li className="bg-white-300 md:mb-[1.875rem]  w-full max-w-full  py-10 px-7 flex flex-col text-center rounded-[1.25rem] justify-center items-center">
                            <StaticImage src="../../assets/img/manufacturing.inline.svg" alt="Manufacturing" />
                            <h3 className="md:text-2xl text-xl pb-1 font-blancotrialbold leading-9 text-center pt-4">
                                Technology & IT
                            </h3>
                            <p className="text-grey text-base leading-6 mt-[10px]">
                                Keep teams updated with the latest tools and skills.
                            </p>
                        </li>
                        <li className="bg-white-300 md:mb-[1.875rem]  w-full max-w-full  py-10 px-7 flex flex-col text-center rounded-[1.25rem] justify-center items-center">
                            <StaticImage src="../../assets/img/gov-sector.inline.svg" alt="Government & Public Sector" />
                            <h3 className="md:text-2xl text-xl pb-1 font-blancotrialbold leading-9 text-center pt-4">
                                Manufacturing
                            </h3>
                            <p className="text-grey text-base leading-6 mt-[10px]">
                                Ensure safety and operational training at scale.
                            </p>
                        </li>

                    </ul>
                    <div className="btn-row mt-6 md:mt-[1.875rem] lg:mt-[3.75rem] text-center">
                        <div onClick={() => goToSection("contact")} className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-transparent hover:text-black uppercase relative pr-[2.3125rem]">Get a Free Demo

                            <span className="inline-flex justify-center items-center rounded-full ml-[0.625rem]  h-[2.125rem] w-[2.125rem] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent absolute right-[5px] top-[5px]">
                                <img
                                    src={ArrowWhite}
                                    alt="ArrowRight"
                                    className="absolute left-1/2 top-4 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                />
                                <img
                                    src={ArrowWhite}
                                    alt="ArrowRight"
                                    className="absolute -left-16 top-4 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2 w-[7px] h-[14px] ease-in duration-300 group-hover:invert-[1] group-hover:brightness-[1]"
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </section>

            <section id="our-work" className="support-sec pt-[40px] md:pt-[56px] lg:pt-[90px] py-14 pb-0 relative home-page-portfolio">
                <div className="container">
                    <div className="w-full title-sec max-sm:pb-6 pb-12">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium text-center">
                            E-learning projects <span className="text-primary italic">built by</span> our team.
                        </h2>
                    </div>
                    <ProjectPortfolio projectList={projects} showBudget={false} showProjectTechnologies />
                </div>
            </section>

            <section className="hiring-steps bg-[#F8F7F3] py-10 md:py-12 xl:py-[6.875rem] overflow-hidden">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">Our product development process</h2>
                        <p className="text-lg">Hire Techuz's expert React developers on-demand to meet your project's needs. Our skilled professionals seamlessly integrate into your team, providing flexible and specialized support whenever you need it. Here's how it works:</p>
                    </div>
                    <ul className="grid grid-cols-1 lg:grid-cols-3 gap-x-[6.25rem] gap-y-[1.875rem] mt-10 relative z-10 before:content-['*'] before:h-[0.063rem] before:absolute before:w-[500%] before:bg-black before:top-8 before:left-[50%] before:-translate-x-1/2 before:-z-10 max-lg:before:w-[0.063rem] max-lg:before:max-lg:h-full max-lg:before:top-0 max-lg:before:left-6 max-lg:before:translate-x-0">
                        <li className="relative pl-16 lg:pl-0">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-[#3FB955] rounded-[50%] flex items-center justify-center mb-5 bg-[#3FB955] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">

                                <ConsultIcon className="w-[1.5625rem]" />
                            </span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-[0.625rem]">1. Consultation</h4>
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Share your goals and vision.</p>
                        </li>
                        <li className="relative pl-16 lg:pl-0">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-[#3FB955] rounded-[50%] flex items-center justify-center mb-5 bg-[#3FB955] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">
                                {/* <InterviewIcon className="w-[1.5625rem] filter invert" /> */}
                                <DesignIcon className="w-[1.5625rem]" />
                            </span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-[0.625rem]">2. Design & Develop</h4>
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Customization as per requirement.</p>
                        </li>
                        <li className="relative pl-16 lg:pl-0">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-[#3FB955] rounded-[50%] flex items-center justify-center mb-5 bg-[#3FB955] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">
                                {/* <HireDevelopers className="w-[1.5625rem] filter invert" /> */}
                                <LaunchIcon className="w-[1.5625rem]" />
                            </span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-[0.625rem]">3. Launch & Evaluate</h4>
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Deploy and gather feedback for continuous improvement.</p>
                        </li>
                    </ul>
                    <div className="btn-row text-center mt-[50px]">
                        <div
                            onClick={() => goToSection("contact")}
                            className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-transparent hover:text-black uppercase relative pr-[2.3125rem]"
                        >Get Consultation
                            <span className="inline-flex justify-center items-center rounded-full ml-[0.625rem]  h-[2.125rem] w-[2.125rem] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent absolute right-[5px] top-[5px]">
                                <img
                                    src={ArrowWhite}
                                    alt="ArrowRight"
                                    className="absolute left-1/2 top-4 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                />
                                <img
                                    src={ArrowWhite}
                                    alt="ArrowRight"
                                    className="absolute -left-16 top-4 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2 w-[7px] h-[14px] ease-in duration-300 group-hover:invert-[1] group-hover:brightness-[1]"
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </section>

            <section id="testimonial" className="py-8  lg:py-10 xl:py-20 testimonial-sec md:py-[1.875rem]">
                <div className="container">
                    <div className="w-full text-center lg:pb-10 pb-9">
                        <div className="title-label text-center"><span className="font-gorditamedium inline-block bg-cream italic pt-[12px] pb-[9px] px-[1.625rem] rounded-[6.25rem] mb-6 md:mb-[0.625rem] leading-[1.125rem] md:leading-[21px]">According to Clutch.co</span></div>
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">
                            Techuz is one of the <span className="italic text-black">best development and IT firm</span> in the world.
                        </h2>
                        <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem]">And here’s what our clients say about us</p>
                    </div>

                    <TestimonialSlider />

                </div>
            </section >

            <section id="faq">
                <Faq faqData={faqs} />
            </section>

            <LandingFooter />
        </>
    )
}
export const Head = () => <Seo title="LMS Development Company" noIndex />

export default LMSDevelopment